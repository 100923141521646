import { writable } from 'svelte/store';
import { browser } from '$app/environment';

export const STORAGE_KEY = 'notetracker_key';

const getStoredToken = () => {
	if (browser) {
		return window.localStorage.getItem(STORAGE_KEY);
	}
	return null;
};

export const token = writable(getStoredToken());

export const clearStoredToken = () => {
	if (browser) {
		window.localStorage.setItem(STORAGE_KEY, '');
	}
	token.set(null);
};

// listen to this and if true, clear query cache and reset
export const unauthorized = writable(false);
